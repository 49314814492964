<template>
  <div class="bg-gray-100 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="shopping-bag"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Ventas de bazar
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Listado de las ventas de artículos del bazar.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      :useStorage="true"
      :buttons="buttons"
      :managementButtons="true"
      :columnFilters="true" 
      rowsGetter="venta/ventas"
      @mostrar-detalle="verDetalle"
      @rows-filtered="updateTotal"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
    <template v-slot:vnt_total="data">
      <p class="text-gray-800 break-words font-medium text-xs 2xl:text-base">
      $ {{ data.value.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path="lista-ventas"
      :meta="meta"
      :links="links"
      action="venta/paginateVenta"
      v-if="meta && meta.last_page > 1"
    />

    <Modal 
    v-if="modalVisibleDetalle"
    class="z-40"
    >
      <template v-slot:header>
        <div class="text-left pl-2 sm:pl-6 sm:pt-3">
          <h3 class="text-2xl leading-6 font-medium sm:text-3xl text-gray-700">Detalle de venta</h3>
          <p class="mt-1 max-w-2xl text-sm sm:text-base text-gray-500">Aquí encontrarás la información asociada a esta venta.</p>
        </div>
      </template>

      <template v-slot:body>
        <div class="shadow rounded-lg overflow-x-auto">
          <table class="table-auto w-full text-base divide-y divide-gray-200 whitespace-no-wrap overflow-hidden">
            <thead class="bg-blue-500 text-white">
              <tr>
                <th class="px-8 py-3">Producto</th>
                <th class="px-8 py-3">Unitario</th>
                <th class="px-8 py-3">Cantidad</th>
                <th class="px-8 py-3">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr 
              class="text-left border-b border-gray-200"
              v-for="(item,index) in detalleModal" :key="index"
              >
                <td class="px-8 py-3"> {{ item.prod_nombre }} </td>
                <td class="px-8 py-3 text-right"> {{ `$ ${this.formatearCifra(item.prod_precio)}` }} </td>
                <td class="px-8 py-3 text-center"> {{ item.prod_cantidad }} </td>
                <td class="px-8 py-3 text-right"> {{ `$ ${ (item.prod_cantidad * item.prod_precio).toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }` }} </td>          
              </tr>
            </tbody>
          </table>
        </div>
        

      </template>

      <template v-slot:botones>
        <div class="sm:flex sm:flex-row-reverse">
            <button @click="this.cerrarModalDetalle" type="button" class="w-full mt-3 justify-center rounded-md border shadow-sm border-transparent px-4 py-2 font-normal focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto text-xl sm:text-base bg-gray-600 hover:bg-gray-500 text-white">
              Salir
            </button>
            <button v-if="esAnulable" @click="modalAnular = true" type="button" class="w-full mt-3 justify-center rounded-md border shadow-sm border-transparent px-4 py-2 font-normal focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto text-xl sm:text-base bg-red-600 hover:bg-red-500 text-white">
              Anular
            </button>
        </div>
      </template>
    </Modal>

    <Modal 
    v-if="modalAnular"
    :titulo="'¡Un momento!'"
    :parrafo="'La anulación de una venta es un proceso irreversible.'"
    :pregunta="'¿Deseas continuar?'"
    class="z-40"
    >
      <template v-slot:botones>
        <div>
          <div class="">
              <button v-if="esAnulable" @click="this.anularVenta(this.detalleModal[0].vnt_num)" type="button" class="justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-md font-semibold focus:outline-none sm:ml-3 sm:w-auto bg-red-600 hover:bg-red-500 text-white">
                Sí, anular
              </button>
              <button @click="modalAnular = false" type="button" class="mt-3 justify-center rounded-md border shadow-sm px-4 py-2 text-md font-semibold focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                Cancelar
              </button>
          </div>
        </div>
      </template>
    </Modal>

    <p class="font-semibold text-right mr-12 uppercase"> <small>
      Efectivo $ {{ efectivo.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} | Transbank: $ {{ transbank.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} | Cheque $ {{ cheque.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} | Convenio $ {{ convenio.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }}
    </small> </p>
    <p class="font-semibold text-right mr-12">TOTAL ${{ total.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} </p>
  </div>


</template>
<script>
import ListTable from "@/components/ListTable"
import VentaService from "@/services/VentasService"
import Modal from "@/components/Modal";
import { mapGetters } from "vuex"
import store from "@/store/index";
import Pagination from "@/components/Pagination"
import { separarPorMiles } from '@/utils/helpers';

export default {
  name: "ListaVentas",
  setup() {
    // let initials = store.getters['venta/filters']
    let dates = store.getters['venta/dates']

    return {
      fields: {
        'vnt_num' : 'N°',
        'vnt_n_boleta' : 'N° Boleta',
        'vnt_total': 'Total',
        'metodoPago%metodo': 'M. Pago',
        'vnt_estado' : 'Estado',
        'created_at': 'Fecha'
      },
      filters: [
        {
          "type": "none"
        },
        {
          "type": "none"
        },
        {
          "type": "none"
        },
        {
          "type": "none"
        },
        {
          "type": "none"
        },
        {
          "placeholder": "Por fecha",
          "type": "date-range",
          "field": "created_at",
          "initial": dates ? dates : null
        }
      ]
    }
  },
  data() {
    return {
      rows: [
      ],
      buttons: [
        {
          "text": "Ver",
          "event": "mostrar-detalle"
        }
      ],
      modalVisible: false,
      efectivo: 0,
      transbank: 0,
      cheque: 0,
      convenio: 0,
      total: 0,
      modalVisibleDetalle: false,
      modalAnular: false,
      detalleModal: [],
      esAnulable: null
    }
  },
  computed: {
    ...mapGetters("venta", ["error", "meta", "links"])
  },
  methods: {
    cargarVentas() {
      VentaService.getVentas().then((response) => {
        this.rows = response.data.data;
      });
    },
    updateTotal() {
      
      let dates_query = store.getters['venta/dates_query']

      this.efectivo = 0;
      this.transbank = 0;
      this.cheque = 0;
      this.convenio = 0;
      this.total = 0;

      VentaService.getTotales(1, dates_query).then((response) => {
          console.log(response.data);
          this.total      = parseInt(response.data.total);
          this.efectivo   = parseInt(response.data.efectivo);
          this.transbank  = parseInt(response.data.transbank);
          this.cheque     = parseInt(response.data.cheque);
          this.convenio   = parseInt(response.data.convenio);
      })
    },
    verDetalle(value) {
      this.detalleModal = []
      this.esAnulable = value.vnt_estado == 'Anulada' ? 0 : 1;
      VentaService.getDetalle(value.vnt_num).then((response) => {
        this.modalVisibleDetalle = true;
        this.detalleModal = response.data;

      }).then((otSeleccionada) => {
        if(otSeleccionada != null){
          this.abrirModalDetalle();
        }
      });
    },
    anularVenta(vntNum){
      VentaService.anularVenta(vntNum)
        .then((response) => {
          store.dispatch("venta/getVentas");
          this.modalAnular = false;
          this.cerrarModalDetalle();

          this.updateTotal()
          
          this.emitter.emit("notification-set", {
            type: 'success',
            title: response.data.title,
            text: response.data.message,
            time: 0,
            group: 'notifications'
          });
        });      
    },
    abrirModalDetalle() {
      this.modalVisibleDetalle = true;
    },
    cerrarModalDetalle() {
      this.modalVisibleDetalle = false;
    },
    setDates(dates) {
      store.dispatch("venta/setDates", dates)
      store.dispatch("venta/getVentas", 1)
      this.updateTotal()
    },
    formatearCifra(cifra) {
      return separarPorMiles(cifra);
    }
  },
  mounted() {
    // store.dispatch("venta/setDates", dates)
    this.updateTotal()
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1
    store.dispatch("venta/getVentas", currentPage).then(() => {
      to.params.page = currentPage
      next()
    })
  },
  components: {
    ListTable,
    Pagination,
    Modal
  }
}
</script>